import React from "react";
import user from "../assets/icons/userBlack1.png";
import BlueLogo from "../assets/icons/blueLogo.png";
import { HashLink as Link } from "react-router-hash-link";
import "./Style/style.css";

function StickyNavBar() {
  return (
    <div className="navigation h-16 sm:w-screen bg-white flex items-center justify-center fixed z-50 m-auto border-b-1 border-[#5e5c56] overflow-clip drop-shadow">
      <div className="h-[46px] w-[1192px] mx-auto bg-white m-auto">
        <div className="h-[46px] flex justify-between items-center">
          <div className="h-auto w-[230px] object-contain">
            <div className="h-auto my-auto cursor-pointer">
              <a href="/">
                <img
                  src={BlueLogo}
                  alt="Procat Logo"
                  className="h-full w-full"
                />
              </a>
            </div>
          </div>
          <nav className="text-black w-[664px] flex justify-around items-center font-archivo">
            <ul className="flex gap-[36px] text-base font-semibold">
              <li>
                <Link smooth to="#home">
                  Home
                </Link>
              </li>
              <li>
                <Link smooth to="#about">
                  About
                </Link>
              </li>
              <li>
                <Link smooth to="#features">
                  Features
                </Link>
              </li>
              <li>
                <Link smooth to="#faq">
                  FAQ
                </Link>
              </li>
              <li>
                {/* <Link smooth to="#blogs">
                  Blogs
                </Link> */}
              </li>
            </ul>
          </nav>
          <div className="flex w-[262px] h-[46px] gap-[32px] font-archivo">
            <div className="flex items-center w-[80px] [15px] gap-2">
              <img src={user} alt="user Icon" />
              <span className="text-black text-base font-semibold">
                Support
              </span>
            </div>
            <div>
              <button className="w-[150px] h-[46px] rounded-[30px] bg-black text-white font-semibold text-base drop-shadow-md">
              <Link smooth to="#footer">Contact Us</Link>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StickyNavBar;
