import React from "react";
import PhoneImage from "../assets/images/phone-image.png";
import PhoneInside from "../assets/images/phone-inside.png";
import Phone from "../assets/videos/phone.mp4";
import Video from "../components/Video";

function Demo() {
  return (
    <div className="w-screen h-[936px] bg-background bg-cover sm:h-auto sm:relative flex justify-center">
      <div className="sm:w-[1192px] h-auto absolute m-auto flex sm:items-center sm:top-[25%]">
        <div className="flex flex-col h-auto w-[350px] gap-[28px] sm:gap-[40px] sm:h-[352px] sm:z-10 sm:bounce">
          <div className="sm:h-[258px] sm:w-[708.89px] h-[288px] w-[350px] flex flex-col gap-[16px]">
            <span className="font-outfit font-semibold sm:text-[60px] text-[48px] text-white sm:leading-[70px] leading-[54px]">
              Build with flexiblity for best customer experience. 
            </span>
            <span className="font-jakarta text-white sm:text-[24px] sm:leading-[32px] text-[20px] leading-[28px]">
              A great app is essential for a great conversion rate.
            </span>
          </div>
          <button className="w-[249.87px] h-[54px] bg-white rounded-[30px] font-outfit font-semibold text-[17px] leading-[14px] text-[#141125] ">
            <a
              href="https://demo.procart.com.au"
              target="_blank"
              rel="noopener noreferrer"
            >
              Try our Demo App
            </a>
          </button>
          <div className="sm:hidden h-[480px] w-auto text-center m-auto flex justify-center">
            <img src={PhoneImage} alt="" className="h-full" />
            <img
              src={PhoneInside}
              alt=""
              className="absolute bottom-[5px] h-[468.5px] w-[218px] rounded-[31px] overflow-clip"
            />
          </div>
        </div>
      </div>
      <div>
        <Video video={Phone} />
      </div>
    </div>
  );
}

export default Demo;
