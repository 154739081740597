import React from "react";
import { HashLink as Link } from "react-router-hash-link";

function MobilenNavComponents({ closeNav, navItems = [] }) {
  return (
    <div className="bg-transparent bg-none h-auto w-full bg-black flex flex-col items-center gap-[60px] text-white py-[70px] justify-between">
      <ul className="h-auto font-outfit text-[28px] leading-[32px] flex flex-col gap-[70px] px-2">
        {navItems.map((item, index) => (
          <li key={index} className="text-center">
            <Link smooth to={item.link} onClick={closeNav}>
              {item.label}
            </Link>
          </li>
        ))}
      </ul>
      <button className="w-full h-[52px] font-archivo font-semibold text-xl leading-8 bg-white text-black rounded-[30px]">
        <Link smooth to="#footer">
        Contact Us
        </Link>
      </button>
    </div>
  );
}

export default MobilenNavComponents;
