import React from "react";

import Arrow from "../assets/icons/right-arrow.svg";

function ServiceCard({ title, description, icon }) {
  return (
    <div className="sm:w-[375px] w-[358px] h-auto bg-white rounded-[30px] border border-gray-300 p-[50px_32px] gap-[21px] flex flex-col cursor-pointer hover:shadow-lg hover:scale-105 hover:-translate-y-2 transition-all duration-300 ease-in-out hover:drop-shadow-xl">
      <div className="w-[46px] h-[47.16px]">
        <img src={icon} alt="icon" />
      </div>
      <div className="h-auto w-[297.81px] flex flex-col">
        <span className="font-outfit font-semibold text-[28px] text-[#01103D]">
          {title}
        </span>
        <span className="font-jakarta font-medium text-[18px] text-[#5F6168] leading-[#26px]">
          {description}
        </span>
      </div>
      <div className="w-[44px] h-[44px] rounded-full bg-[#E8EAF1] flex justify-center items-center hover:bg-gray-400">
        <img src={Arrow} alt="forward icon" />
      </div>
    </div>
  );
}

export default ServiceCard;
