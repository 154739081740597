import React from "react";
import adressIcon from "../assets/icons/adress.svg";
import contactIcon from "../assets/icons/contact.svg";
import facBookIcon from "../assets/icons/faceBook.svg";
import Instagram from "../assets/icons/instagram.svg";
import linkedInIcon from "../assets/icons/linkidn.svg";
import messageIcon from "../assets/icons/message.svg";
import Icon from "../assets/icons/proCartIcon.svg";
import tickIcon from "../assets/icons/whiteTick.svg";
import InputFroms from "./forms/InputFroms";

import { HashLink as Link } from "react-router-hash-link";

const Footer = () => {
  return (
    <div className="flex flex-col items-center justify-center">
      <div
        id="footer"
        className="h-[1770px] w-screen sm:h-[928.98px] bg-[#000229] sm:mx-5 sm:mt-4 2xl:mt-12 md:min-w-[1190px] rounded-[55px] 2xl:mx-auto border-none shadow-md sm:py-12 sm:px-2 py-4"
      >
        <div>
          <div className="sm:h-[707.27px] w-auto h-auto 2xl:w-[1285px] sm:w-auto 2xl:mx-auto sm:mx-10 my-5">
            <div className="h-full w-auto flex flex-col justify-between items-center">
              {/* top section cord */}
              <div className="sm:h-auto sm:w-[1194px] h-[662px] w-[334px]">
                <div className="h-full w-full flex sm:flex-row flex-col justify-between items-center">
                  <div className="sm:h-[312px] sm:w-[418px] h-[272px] w-[334px] flex flex-col justify-between sm:gap-[32px] gap-3">
                    <div className="h-[116px] w-[327px]">
                      <div>
                        <p className="font-jakarta text-[#FFFFFF] text-sm font-normal tracking-wider">
                          CONTACT US
                        </p>
                      </div>
                      <div>
                        <p className="font-outfit text-white text-3xl font-bold mt-3">
                          Get Our Latest's News & Updates
                        </p>
                      </div>
                    </div>
                    <div className="h-[164px] w-[340px] flex flex-col justify-between">
                      {/* first one */}
                      <div
                        className="h-[44px] w-[340px] border-none rounded-[30px] bg-gradient-to-r from-[rgba(255, 255, 255, 0.3)] to-[rgba(255, 255, 255, 0.1)] flex gap-4 items-center px-4 shadow"
                        style={{
                          backdropFilter: "blur(10px)",
                          background:
                            "linear-gradient(to right, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.0))",
                        }}
                      >
                        <span className="h-[22px] w-[22px]">
                          <img src={tickIcon} alt="Thick icon" />
                        </span>
                        <span className="w-auto">
                          <p className="font-jakarta text-base  text-[#FFFFFF]">
                            No training or maintenance needed.
                          </p>
                        </span>
                      </div>
                      {/* second one */}
                      <div
                        className="h-[44px] w-[290px] border-none rounded-[30px] bg-gradient-to-r from-[#ffffffad] to-[#FFFFFF00] flex gap-4 items-center px-4 py-3 shadow"
                        style={{
                          backdropFilter: "blur(10px)",
                          background:
                            "linear-gradient(to right, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.0))",
                        }}
                      >
                        <span className="h-[22px] w-[22px] flex items-center">
                          <img src={tickIcon} alt="Tick icon" />
                        </span>
                        <span className="flex items-center">
                          <p className="font-jakarta text-base text-[#FFFFFF]">
                            100% security. Guaranteed.
                          </p>
                        </span>
                      </div>

                      {/* third one */}
                      <div
                        className="h-[44px] w-[200px] border-none rounded-[30px] bg-gradient-to-r from-[#ffffffad] to-[#FFFFFF00] flex gap-4 items-center px-4 py-3 shadow"
                        style={{
                          backdropFilter: "blur(10px)",
                          background:
                            "linear-gradient(to right, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.0))",
                        }}
                      >
                        <span>
                          <img src={tickIcon} alt="Thick icon" />
                        </span>
                        <span>
                          <p className="font-jakarta text-base text-[#FFFFFF]">
                            No hidden fees.
                          </p>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="sm:h-[312px] sm:w-[680px] h-[370px] w-[334px]">
                    <div className="block">
                      <InputFroms />
                    </div>
                    <div className="hidden">
                      <InputFroms />
                    </div>
                  </div>
                </div>
              </div>
              {/* hr line */}
              <div className="sm:w-[899.6px] w-[334px] h-[2px] bg-gradient-to-r from-[#FFFFFF00] via-[#FFFFFF27] to-[#FFFFFF00 mx-auto 2xl:mt-11 mt-8" />
              <div className="sm:h-[315.26px] sm:w-[1194.42px] h-[840.01px] w-[334px] my-6 bg-transparent">
                <div className="h-full w-full flex sm:flex-row flex-col sm:flex-wrap justify-between items-start sm:gap-4">
                  <div className="sm:h-[186.01px] sm:w-[283px] h-[176.01px] w-[334px] flex flex-col justify-between sm:gap-4">
                    <div>
                      <img
                        src={Icon}
                        alt="Icon"
                        height={32.01}
                        width={153.05}
                      />
                    </div>
                    <div>
                      <span className="font-jakarta text-base font-normal text-white">
                        Our highly skilled development teams specialized in
                        e-commerce
                      </span>
                    </div>
                    <div className="flex w-auto h-[50px] gap-3">
                      <span>
                        <a
                          // href="https://facebook.com/Procart"
                          href="https://www.facebook.com/people/Procart/61560315520998/?mibextid=qi2Omg&rdid=fZDgODvkvIrmRU5B&share_url=https%3A%2F%2Fwww.facebook.com%2Fshare%2FvairhUpC7WEWCQag%2F%3Fmibextid%3Dqi2Omg"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src={facBookIcon}
                            height={42}
                            width={42}
                            alt="facebookIcon"
                          />
                        </a>
                      </span>
                      <span>
                        <div className="h-[42px] w-[42px] rounded-[30px] border-[1.5px] border-[#FFFFFF24] flex justify-center items-center">
                        <a
                          href="https://instagram.com/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src={Instagram}
                            height={24}
                            width={24}
                            alt="instagramIcon"
                          />
                        </a>
                        </div>
                      </span>
                      <span>
                        <a
                          href="https://www.linkedin.com/in/Procart"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src={linkedInIcon}
                            height={42}
                            width={42}
                            alt="LinkedIn"
                          />
                        </a>
                      </span>
                    </div>
                  </div>
                  <div className="sm:h-[292px] sm:w-[188.06px] h-[160px] w-[334px] flex flex-col items-start gap-4">
                    <div>
                      <span className="font-archivo text-lg font-semibold text-white">
                        What We Do
                      </span>
                    </div>
                    <span>
                      <ul>
                        <li className="text-base font-medium text-[#F5F5FA99] font-jakarta sm:leading-8 leading-7">
                          Ecommerce Solution
                        </li>
                        <li className="text-base font-medium text-[#F5F5FA99] font-jakarta sm:leading-8 leading-7">
                          Shopify Development
                        </li>
                        <li className="text-base font-medium text-[#F5F5FA99] font-jakarta sm:leading-8 leading-7">
                          Theme Development
                        </li>
                        <li className="text-base font-medium text-[#F5F5FA99] font-jakarta sm:leading-8 leading-7">
                          Figma Design
                        </li>
                      </ul>
                    </span>
                  </div>
                  <div className="sm:h-[265px] sm:w-[155px] h-[200px] w-[334px] flex flex-col gap-4">
                    <div>
                      <span className="font-archivo text-lg text-white font-semibold">
                        Other Pages
                      </span>
                    </div>
                    <div className="text-base font-medium font-jakarta text-[#F5F5FA99]">
                      <ul className="leading-8">
                        <li>
                          <Link smooth to="#about">
                            About
                          </Link>
                        </li>
                        <li>
                          <Link smooth to="#features">
                            Services
                          </Link>
                        </li>
                        <li>
                          <Link smooth to="">
                            How It Works
                          </Link>
                        </li>
                        <li>
                          <Link smooth to="">
                            Pricing Plan
                          </Link>
                        </li>
                        <li>
                          <Link smooth to="#blogs">
                            Blog
                          </Link>
                        </li>
                        <li>
                          <Link smooth to="#contacts">
                            Contact
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="sm:h-[217px] sm:w-[251.87px] h-[170px] w-[334px] flex flex-col gap-4">
                    <div className="font-archivo text-lg text-white font-semibold">
                      Contact Us
                    </div>
                    <div className="flex gap-2 items-center">
                      <span>
                        <img
                          src={contactIcon}
                          height={20}
                          width={20}
                          alt="contactIcon"
                        />
                      </span>
                      <span className="font-jakarta text-base font-medium text-[#F5F5FA]">
                        +61 414 307 399
                      </span>
                    </div>
                    <div className="flex gap-2 items-center">
                      <span>
                        <img
                          src={messageIcon}
                          height={16}
                          width={16}
                          alt="messageIcon"
                        />
                      </span>
                      <div className="flex">
                        <span className="font-jakarta text-base font-medium text-[#F5F5FA]">
                          i
                        </span>
                        <span className="font-jakarta text-base font-medium text-[#F5F5FA]">
                          n
                        </span>
                        <span className="font-jakarta text-base font-medium text-[#F5F5FA]">
                          f
                        </span>
                        <span className="font-jakarta text-base font-medium text-[#F5F5FA]">
                          o
                        </span>
                        <span className="font-jakarta text-base font-medium text-[#F5F5FA]">
                          @
                        </span>
                        <span className="font-jakarta text-base font-medium text-[#F5F5FA]">
                          p
                        </span>
                        <span className="font-jakarta text-base font-medium text-[#F5F5FA]">
                          r
                        </span>
                        <span className="font-jakarta text-base font-medium text-[#F5F5FA]">
                          o
                        </span>
                        <span className="font-jakarta text-base font-medium text-[#F5F5FA]">
                          c
                        </span>
                        <span className="font-jakarta text-base font-medium text-[#F5F5FA]">
                          a
                        </span>
                        <span className="font-jakarta text-base font-medium text-[#F5F5FA]">
                          r
                        </span>
                        <span className="font-jakarta text-base font-medium text-[#F5F5FA]">
                          t
                        </span>
                        <span className="font-jakarta text-base font-medium text-[#F5F5FA]">
                          .
                        </span>
                        <span className="font-jakarta text-base font-medium text-[#F5F5FA]">
                          c
                        </span>
                        <span className="font-jakarta text-base font-medium text-[#F5F5FA]">
                          o
                        </span>
                        <span className="font-jakarta text-base font-medium text-[#F5F5FA]">
                          m
                        </span>
                        <span className="font-jakarta text-base font-medium text-[#F5F5FA]">
                          .
                        </span>
                        <span className="font-jakarta text-base font-medium text-[#F5F5FA]">
                          a
                        </span>
                        <span className="font-jakarta text-base font-medium text-[#F5F5FA]">
                          u
                        </span>
                      </div>
                    </div>
                    <div className="flex gap-2 items-start">
                      <span>
                        <img
                          src={adressIcon}
                          height={28}
                          width={28}
                          alt="adresssIcon"
                        />
                      </span>
                      <span className="font-jakarta text-base font-medium text-[#F5F5FA]">
                        15/43, Sheffield St, MerryLands, NSW, Australia
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* horizontal line */}
          <div className="sm:mt-16 my-5">
            <div className="max-w-[899.6px] h-[2px] bg-gradient-to-r from-[#FFFFFF00] via-[#FFFFFF27] to-[#FFFFFF00 m-auto" />
            <div className="sm:w-[943.43px] sm:h-4 w-[334px] h-[45.28px] mx-auto flex sm:flex-row flex-col justify-between items-center mt-9 gap-2">
              <div className="h-full w-[325.27px]">
                <p className="font-medium text-sm text-[#F5F5FA99] font-jakarta">
                  Copyrights <span>&copy;</span> 2024. Procart All rights reserved
                </p>
              </div>
              <div>
                <a href="https://probits.com.au/" target="_blank" rel="noopener noreferrer" class="cursor-pointer">
                  <p className="font-medium text-sm text-[#F5F5FA99] font-jakarta underline">Product by Probits</p>
                </a>
              </div>
              <div className="h-full w-[215.76px] flex justify-between items-center">
                <div className="h-full sm:min-h-[25px] w-[101.88px]">
                  <Link
                    to=""
                    className="font-jakarta text-[#F5F5FA99] font-medium text-sm underline"
                  >
                    Term of service
                  </Link>
                </div>
                <div className="h-full sm:min-h-[25px] w-[101.88px]">
                  <Link
                    to=""
                    className="font-jakarta text-[#F5F5FA99] font-medium text-sm underline"
                  >
                    Privacy Policy
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
