import axios from "axios";
import parse, { domToReact } from "html-react-parser";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Tick from "../assets/icons/tick.svg";
import picture from "../assets/images/picture.png";
import Footer from "../components/Footer";
import MobileNav from "../components/MobileNav";
import Navbar from "../components/Navbar";
import Blogs from "./Blogs";

function BlogDescription() {
  const { id } = useParams();
  const [blog, setBlog] = useState(null);

  const [isNavOpen, setIsNavOpen] = useState(false);

  const handleMenuToggle = () => {
    setIsNavOpen(!isNavOpen);
  };

  const fetchBlogById = async () => {
    try {
      const response = await axios.get(
        `https://blog.procart.com.au/api/blog/${id}`
      );
      setBlog(response.data);
      console.log(response.data);
    } catch (error) {
      console.error("Error fetching the blog:", error);
    }
  };

  useEffect(() => {
    fetchBlogById();
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [id]);

  if (!blog) return <div>Loading...</div>;

  const formatDate = (dateString) => {
    const options = { day: "numeric", month: "long", year: "numeric" };
    const date = new Date(dateString).toLocaleDateString("en-GB", options);
    const parts = date.split(" ");
    return `${parts[0]} ${parts[1]}, ${parts[2]}`;
  };

  const navItems = [
    { label: "Home", link: "/#home" },
    { label: "About", link: "/#about" },
    { label: "Features", link: "/#features" },
    { label: "FAQ", link: "/#faq" },
    { label: "Blogs", link: "#blogs" },
  ];

  return (
    <>
      <div className="w-screen sm:h-[1100px] h-[900px]">
        <div className="h-[707.24px] bg-background bg-cover w-full mx-auto flex justify-center">
          <div className="absolute w-[1192px] z-10 m-auto">
            <div className="hidden sm:block">
              <Navbar navItems={navItems} />
            </div>
            <div className="sm:hidden">
              <MobileNav
                isNavOpen={isNavOpen}
                handleMenuToggle={handleMenuToggle}
                navItems={navItems}
              />
            </div>
            {!isNavOpen && (
              <div className="">
                <div className="sm:h-auto sm:w-full mt-[150px] flex flex-col gap-[10px] w-[351px] h-auto mx-auto">
                  <div className="h-auto sm:w-[803.46px] w-full flex flex-col gap-[7px]">
                    <div className="h-[30px] w-[113.62px] rounded-[20px] border-2 border-[#FFFFFF] px-[20px] py-[3px] font-jakarta font-semibold text-sm text-white">
                      Resources
                    </div>
                    <span className="font-outfit font-semibold sm:text-[70px] sm:leading-[80px] text-[40px] leading-[48px] text-white">
                      {blog.title}
                    </span>
                  </div>
                  <div className="h-[60.91px] w-[249.56px] flex gap-3 items-center">
                    <div className="h-full w-[56.81px] rounded-[30px] bg-white">
                      <img src={picture} alt="" className="object-cover" />
                    </div>
                    <div className="h-[40.25px] w-auto flex flex-col justify-between text-white">
                      <span className="font-outfit font-medium text-base">
                        {blog.author}
                      </span>
                      <p className="font-outfit text-[13px] leading-[10px] text-[#FFFFFFB2]">
                        {formatDate(blog.createdAt)}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="sm:w-[988px] sm:h-[570px] h-[373.35px] w-screen mx-auto mt-8 sm:rounded-[30px]">
                  <img
                    src={blog.imageUrl}
                    alt=""
                    className="h-full w-full sm:rounded-[30px]"
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="blog-description-container sm:w-[784px] m-auto sm:pt-20 pt-10 w-[351px]">
        {parse(blog.content, {
          replace: (domNode) => {
            if (domNode.name === "h2") {
              return (
                <span className="font-outfit font-semibold text-[32px] leading-[27px] text-[#080829]">
                  {domToReact(domNode.children)}
                </span>
              );
            }
            if (domNode.name === "h3") {
              return (
                <span className="font-outfit font-semibold text-2xl text-[#0c0c30]">
                  {domToReact(domNode.children)}
                </span>
              );
            }
            if (domNode.name === "strong") {
              return (
                <span className="font-outfit font-semibold text-xl text-[#01103D]">
                  {domToReact(domNode.children)}
                </span>
              );
            }
            if (domNode.name === "p") {
              return (
                <span className="font-outfit font-medium text-lg text-[#5F6168]">
                  {domToReact(domNode.children)}
                </span>
              );
            }
            if (domNode.name === "li") {
              return (
                <div className="w-auto flex gap-[15px] items-center">
                  <div className="w-[20px] rounded-2xl bg-[#6E18C5] flex justify-center items-center">
                    <img src={Tick} alt="" />
                  </div>
                  <span className="font-outfit font-semibold text-sm text-[#01103D]">
                    {domToReact(domNode.children)}
                  </span>
                </div>
                // <span className="font-outfit font-medium text-lg text-[#5F6168]">
                //   {domToReact(domNode.children)}
                // </span>
              );
            }
            if (domNode.name === "li > strong") {
              return (
                <span className="font-jakarta font-semibold text-xl text-[#01103D]">
                  {domToReact(domNode.children)}
                </span>
              );
            }
          },
        })}
      </div>
      <Blogs />
      <Footer />
    </>
  );
}

export default BlogDescription;
