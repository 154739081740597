import React from "react";
import { HashLink as Link } from "react-router-hash-link";
import user from "../assets/icons/user-icon.png";
import logo from "../assets/images/procart-logo.png";
import {Link as HashLink} from "react-router-hash-link"

function Navbar({ navItems }) {
  return (
    <div className="h-[46px] w-[1192px] mx-auto top-[38px] z-10 absolute">
      <div className="h-[46px] flex justify-between items-center">
        <div className="h-[32px] w-[266px]">
          <div className="h-[32px] my-auto">
            <a href="/"><img src={logo} alt="" className="h-full w-full" /></a>
            
          </div>
        </div>
        <nav className="text-white w-[664px] flex justify-around items-center font-archivo">
          <ul className="flex gap-[36px] text-base leading-[14px]">
            {navItems.map((item, index) => (
              <li key={index}>
                <Link smooth to={item.link}>
                  {item.label}
                </Link>
              </li>
            ))}
          </ul>
        </nav>
        <div className="flex w-[262px] h-[46px] gap-[32px] font-archivo">
          <div className="flex items-center w-[80px] [15px] gap-2">
            <img src={user} alt="" />
            <span className="text-white text-base font-semibold">Support</span>
          </div>
          <div>
            <button className="w-[150px] h-[46px] rounded-[30px] bg-white font-semibold text-base">
              <Link smooth to="#footer">Contact Us</Link>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
