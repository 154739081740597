import React, { useEffect, useState } from "react";
import HomeContent from "../components/HomeContent";
import MobileNav from "../components/MobileNav";
import Navbar from "../components/Navbar";
import StickyNavBar from "../components/StickyNavBar";
import Background from "../assets/images/laptop.webp";

function Home() {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [showScrollNavbar, setShowScrollNavbar] = useState(false);

  const handleMenuToggle = () => {
    setIsNavOpen(!isNavOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      if (scrollY > 100) {
        setShowScrollNavbar(true);
      } else {
        setShowScrollNavbar(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const navItems = [
    { label: "Home", link: "#home" },
    { label: "About", link: "#about" },
    { label: "Features", link: "#features" },
    { label: "FAQ", link: "#faq" },
    // { label: "Blogs", link: "#blogs" },
  ];

  return (
    <>
      <div
        id="home"
        className="relative h-[874px] sm:h-auto lg:min-h-max w-screen bg-background bg-cover"
      >
        <div className="hidden sm:w-[1192px] sm:mx-auto sm:flex justify-center smooth">
          {showScrollNavbar ? (
            <StickyNavBar navItems={navItems} />
          ) : (
            <Navbar navItems={navItems} />
          )}
        </div>
        <div className="block sm:hidden">
          <MobileNav
            isNavOpen={isNavOpen}
            handleMenuToggle={handleMenuToggle}
            navItems={navItems}
          />
        </div>
        {!isNavOpen && <HomeContent />}
      </div>
      <div className="hidden sm:block sm:h-auto lg:h-[800px] 2xl:h-[900px] w-auto bg-background bg-cover">
        {/* <Video video={Laptop} className="h-full w-full"/> */}
        <img src={Background} alt="" className="h-full w-full" />
      </div>
    </>
  );
}

export default Home;
