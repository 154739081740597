import React, { useState } from "react";
import CloudyFox from "../assets/icons/cloudyfox-logo.png";
import HomeHandy from "../assets/icons/hh-logo.svg";
import HurryApp from "../assets/icons/hurryapp-icon.svg";
import Kiindred from "../assets/icons/kindred-logo.png";
import Probits from "../assets/icons/probits-logo.svg";
import SuperMarket from "../assets/icons/supermarket-swap.png";
import "../components/Style/style.css";
import KiindredWhite from "../assets/icons/changing-icon/kindred-logo.svg";
import SuperMarketWhite from "../assets/icons/changing-icon/supermarket-swap.svg";
import ProbitsWhite from "../assets/icons/changing-icon/probits-logo.svg";
import HurryAppWhite from "../assets/icons/changing-icon/hurryapp-icon.svg";
import HomeHandyWhite from "../assets/icons/changing-icon/hh-logo.svg";
import CloudyFoxWhite from "../assets/icons/changing-icon/cloudyfox-logo.svg";

const OurClient = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const clientLog = [
    { logo: Kiindred },
    { logo: SuperMarket },
    { logo: Probits },
    { logo: HurryApp },
    { logo: CloudyFox },
    { logo: HomeHandy },
  ];

  const details = [
    {
      icon: KiindredWhite,
      content:
        "Working with Probits has brought my business to absolute new heights and they are now part of the family. We could not be more pleased with the work of our dedicated team. I have and will recommend them to anyone looking for their services.",
      name: "Sabir Samtani",
      position: "Founder",
    },
    {
      icon: SuperMarketWhite,
      content:
        "Choosing Procart was one of our best decisions. Their intuitive, robust, and scalable e-commerce platform has given us a competitive edge. Highly recommended!",
      name: "Nabula",
      position: "Founder",
    },
    {
      icon: ProbitsWhite,
      content:
        "Working with Procart has taken our business to new heights. Their dedication and expertise have made them an integral part of our team. We are extremely pleased with the results and highly recommend Procart for their top-tier services and support.",
      name: "Dinesh Lamsal",
      position: "Founder",
    },
    {
      icon: HurryAppWhite,
      content:
        "Choosing Procart was one of our best decisions. Their robust, scalable, and intuitive e-commerce platform, along with custom solutions, has given us a competitive edge. Highly recommended!",
      name: "Chris",
      position: "Founder",
    },
    {
      icon: CloudyFoxWhite,
      content:
        "Procart's custom e-commerce platform has transformed our business, streamlining operations and boosting customer satisfaction. We're thrilled with its performance and the significant increase in sales.",
      name: "Sambridh Pyakurel",
      position: "Founder",
    },
    {
      icon: HomeHandyWhite,
      content:
        "Working with Procart has been fantastic. Their custom e-commerce platform has simplified our processes, boosted our online presence, and improved sales and customer satisfaction with its ease of use and powerful features.",
      name: "Mark Schmidt",
      position: "Founder",
    },
  ];

  const currentDetail = details[currentIndex];

  return (
    <div className="h-auto w-screen py-14">
      <div className="sm:h-[686px] sm:w-[1190px] mx-auto flex items-center justify-center w-[390px] h-[901.59px]">
        <div className="h-full w-full flex items-center flex-col justify-between">
          {/* First Container */}
          <div className="sm:h-[97px] sm:w-[504px] h-[110px] w-[358px] mx-auto text-center flex flex-col justify-between gap-1">
            <div className="h-auto w-full">
              <p className="font-outfit font-bold sm:text-[50px] text-4xl">
                Word From
                <span className="font-outfit text-[#01103D]"> Our Client</span>
              </p>
            </div>
            {/* <div className="h-auto w-[372px] mx-auto">
              <p className="font-jakarta text-[#5F6168] sm:text-lg text-base font-normal text-center">
                5 Stars Based on 4,700+ Real Users Reviews
              </p>
            </div> */}
          </div>
          {/* Second Container */}
          <div>
            <div className="bg-white sm:w-[988px] sm:h-[420px] drop-shadow-2xl rounded-[30px] border-none border mx-auto h-[653px] w-[358px]">
              <div className="flex sm:flex-row flex-col">
                <div className="sm:h-[420px] sm:w-[580px] h-[443px] w-[357px] bg-gradient-to-r to-[#926EFB] via-[#465BD7] from-[#4A56AB] sm:rounded-tr-[60px] sm:rounded-l-[30px] border-indigo-500 sm:border-none rounded-3xl flex items-center justify-center">
                  <div className="sm:h-[299.76px] sm:w-[431px] w-[293px] h-[369.76px] flex flex-col items-start justify-between gap-8">
                    <div className="h-[45.76px] w-[135.2px]">
                      <img
                        src={currentDetail.icon}
                        alt=""
                        className="h-full w-auto"
                      />
                    </div>
                    <div className="sm:h-[170px] sm:w-[431px] w-[293px] h-[256px] font-jakarta font-medium sm:text-xl text-base text-white leading-[34px]">
                      <p>{`“${currentDetail.content}”`}</p>
                    </div>
                    <div className="h-[40px] w-auto">
                      <div className="font-jakarta text-base font-bold text-white">
                        <p>{currentDetail.name}</p>
                      </div>
                      <div className="font-jakarta text-sm font-medium text-[#FFFFFFB2]">
                        {currentDetail.position}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col items-start sm:py-8 sm:my-8 sm:mx-12 sm:px-3 pt-10 px-7 w-auto h-auto">
                  <p className="text-4xl font-bold text-[#01103D] font-jakarta">
                    94%
                  </p>
                  <div className="h-auto sm:w-[340px] w-[300px] py-3">
                    <p className="sm:text-2xl text-base text-gray-500 font-jakarta">
                      Increase in offline purchases
                    </p>
                    <p className="sm:text-2xl text-base text-gray-500 font-jakarta">
                      versus control group.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* Third Container */}
            <div className="sm:h-auto sm:w-full w-[358px] h-auto m-auto text-center grid grid-cols-3 sm:flex items-center sm:mt-10 mt-4">
              {clientLog.map((item, index) => (
                <div
                  key={index}
                  className={`h-[38px] w-full flex justify-center transition-transform duration-300 ${
                    currentIndex === index
                      ? "transform scale-105"
                      : "transform scale-75 bg-opacity-10 opacity-80"
                  } hover:scale-105`}
                >
                  <img
                    src={item.logo}
                    alt="client_logo"
                    className="cursor-pointer object-contain"
                    onClick={() => setCurrentIndex(index)}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurClient;
