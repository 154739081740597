import React from "react";
import { useState,useRef } from "react";
import emailjs from '@emailjs/browser';

const InputForms = () => {
  const form = useRef();
  const [inputValue, setInputValue] = useState({
    fullName: "",
    email: "",
    organization: "",
    message: "",
  });
  const[name, setName]=useState('')
  const[email, setEmail]=useState('')
  const[message, setMessage]=useState('')
  const[organization, setOrganization] = useState('')

  const handleInput = (event) => {
    setTimeout(() => {
      const { name, value } = event.target;
      setInputValue((prevInputValue) => ({
        ...prevInputValue,
        [name]: value,
      }));
    }, 600);
    // const { name, value } = event.target;
    // setInputValue((prevInputValue) => ({
    //   ...prevInputValue,
    //   [name]: value
    // }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    emailjs.sendForm('service_lvt3bjo', 'template_xg8o3qe', form.current, 'DF3BWoxx94c6IsATm')
    .then((result) => {
        console.log(result.text);
    }, (error) => {
        console.log(error.text);
    });

      setName('');
      setEmail('');
      setMessage('');
      setOrganization('')
      alert("Forms was subbmitted.");
  };

  return (
    <div className="h-auto w-auto">
      <form ref={form} onSubmit={handleSubmit}>
        <div className="h-auto w-full max-w-[680px] flex flex-col gap-7 justify-between">
          <div className="flex flex-wrap gap-4">
            <div className="flex-1 min-w-[332px]">
              <input
                type="text"
                id="first-name"
                name="name"
                placeholder="Full Name"
                required
                className="block w-full rounded-md border-0 py-[10px] px-[18px] shadow-sm bg-[#17193D] text-white placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 focus:outline-none"
                // onChange={handleInput}
                value={name}
                onChange={event => setName(event.target.value)}
              />
            </div>
            <div className="flex-1 min-w-[332px]">
              <input
                type="email"
                name="email"
                id="email"
                placeholder="Email address"
                required
                className="block w-full rounded-md border-0 py-[10px] px-[18px] shadow-sm bg-[#17193D] text-white placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 focus:outline-none"
                // onChange={handleInput}
                value={email}
                onChange={event => setEmail(event.target.value)}
              />
            </div>
          </div>
          <div className="w-full">
            <div className="w-full">
              <input
                type="text"
                id="organization"
                name="organization"
                placeholder="Organization (Optional)"
                className="block w-full rounded-md border-0 py-[10px] px-[18px] shadow-sm bg-[#17193D] text-white placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 focus:outline-none"
                // onChange={handleInput}
                value={organization}
                onChange={event => setOrganization(event.target.value)}
              />
            </div>
          </div>
          <div className="w-full">
            <textarea
              id="message"
              name="message"
              rows="4"
              placeholder="Your message"
              required
              className="block w-full h-24 rounded-md border-none py-2 px-3 text-white bg-[#17193D] shadow-sm placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6 resize-none focus:outline-none"
              value={message}
              onChange={event => setMessage(event.target.value)}
            ></textarea>
          </div>
          <button
            type="submit"
            className="w-full h-[44px] text-white bg-gradient-to-r from-[#926EFB] via-[#465BD7] to-[#4A56AB] rounded-3xl shadow-lg transition duration-300 ease-in-out transform hover:scale-95 hover:from-[#625794] hover:via-[#20329c] hover:to-[#3f4374]"
            value="Send"
          >
            Send Message
          </button>
        </div>
      </form>
    </div>
  );
};

export default InputForms;
