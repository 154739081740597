import React from "react";
import { HashLink as Link } from "react-router-hash-link";
import arrowIcon from "../assets/icons/forwardIcon.svg";
import stars from "../assets/images/Stars.png";
import LaptopandMobile from "../assets/images/laptop-image.png";
import lines from "../assets/images/lines.png";
import "./Style/style.css";

const peopleList = [
  {
    id: 1,
    name: "John Doe",
    designation: "Software Engineer",
    image:
      "https://images.unsplash.com/photo-1599566150163-29194dcaad36?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=3387&q=80",
  },
  {
    id: 2,
    name: "Robert Johnson",
    designation: "Product Manager",
    image:
      "https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8YXZhdGFyfGVufDB8fDB8fHww&auto=format&fit=crop&w=800&q=60",
  },
  {
    id: 3,
    name: "Jane Smith",
    designation: "Data Scientist",
    image:
      "https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NXx8YXZhdGFyfGVufDB8fDB8fHww&auto=format&fit=crop&w=800&q=60",
  },
  {
    id: 4,
    name: "Emily Davis",
    designation: "UX Designer",
    image:
      "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTB8fGF2YXRhcnxlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=800&q=60",
  },
];

function HomeContent() {
  return (
    <div className="sm:w-[1192px] m-auto">
      <div className="relative flex h-auto w-[358px] mx-auto top-[92px] sm:w-[603.88px] flex-col gap-[30px] sm:absolute sm:top-[150px] z-30">
        <div className="h-[156px] sm:h-[234px] w-[358px] sm:w-[570px] fade-in-left">
          <span className="text-[44px] leading-[52px] sm:text-[68px] sm:leading-[78px] font-bold text-white">
            Empower your business with procart platform
          </span>
          <img
            src={lines}
            alt=""
            className="sm:w-[530.98px] w-[360px] h-[24px]"
          />
        </div>
        <p className="text-base sm:text-lg font-jakarta text-[#DFE0E0] fade-in-left-slow">
          We specialize in creating custom web platforms and mobile marketplaces
          that align perfectly with your business requirements, to offer a
          seamless marketplace experience.
        </p>
        <div className="sm:w-[360px] w-[358px] h-[56px] flex gap-[24px] items-center fade-in-left-slow">
          <button className="h-[56px] w-[173px] rounded-[58px] text-[16px] text-white bg-[#926EFB] hover:bg-[#7354D6] hover:shadow-lg transition duration-300 ease-in-out transform hover:scale-90">
            <Link smooth to="#footer">
              Get Started Now
            </Link>
          </button>

          <div className="flex sm:w-[160px] h-[28px] w-auto items-center gap-[10px] transition duration-300 ease-in-out transform hover:scale-90 cursor-pointer">
            <p className="text-white text-[16px] leading-[24px] font-archivo font-semibold">
              <a
                href="https://demo.procart.com.au"
                target="_blank"
                rel="noopener noreferrer"
              >
                Watch Demo
              </a>
            </p>
            <span>
              <img src={arrowIcon} alt="Arrow Icon" />
            </span>
          </div>
        </div>
        <div className="sm:h-[74px] sm:w-[407px] h-[69px] w-[355px] flex sm:gap-3 fade-in-left justify-between items-center">
          {/* <div className="flex flex-row items-center justify-center mt-3 sm:min-w-[160px] h-[52px] max-w-[150px]">
            <AnimatedTooltip items={peopleList} />
          </div> */}
          <div className="h-[69px] w-[179px] sm:h-[74px] sm:w-[201px]">
            <div className="flex gap-2">
              <span className="sm:text-[36px] text-[32px] font-semibold leading-[44.8px] sm:leading-[50.4px] font-outfit text-[#5869FC] pt-1 h-[50px]">
                4.5
              </span>
              <div className="w-[100px] h-[20px] mt-5">
                <img src={stars} alt="stars" />
              </div>
            </div>
            {/* <span className="font-outfit text-[16px] sm:text-[18px] text-white leading-[24px] sm:leading-[27px]">
              5k+ Satisfied Customers
            </span> */}
          </div>
        </div>
        <div className="block sm:hidden">
          <img src={LaptopandMobile} alt="" />
        </div>
      </div>
    </div>
  );
}

export default HomeContent;
