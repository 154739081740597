import React from "react";
import loader from '../loader.gif'
const Loader = () => {
  return (
    <div className="flex justify-center items-center h-screen w-screen bg-black">
      <img src={loader} 
      alt="loder" 
      height={300}
      width={300}/>
    </div>
  );
};

export default Loader;
