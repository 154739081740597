import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DownIcon from "../assets/icons/down-icon.svg";

function Blogs() {
  const [blogs, setBlogs] = useState([]);
  const [showAllBlogs, setShowAllBlogs] = useState(false);
  const navigate = useNavigate();

  const fetchBlogs = async () => {
    try {
      const response = await axios.get("https://blog.procart.com.au/api/blog");
      const data = response.data.blogs;
      setBlogs(data);
      console.log(data);
    } catch (error) {
      console.error("Error fetching the blogs:", error);
    }
  };

  useEffect(() => {
    fetchBlogs();
  }, []);

  const formatDate = (dateString) => {
    const options = { day: "numeric", month: "long", year: "numeric" };
    const date = new Date(dateString).toLocaleDateString("en-GB", options);
    const parts = date.split(" ");
    return `${parts[0]} ${parts[1]}, ${parts[2]}`;
  };

  const handleViewAllBlogs = () => {
    setShowAllBlogs(!showAllBlogs);
  };

  const handleBlogClick = (id) => {
    navigate(`/blog/${id}`);
  };

  return (
    <div id="blogs" className="sm:h-auto w-screen h-auto py-3">
      <div className="h-full sm:w-[1192px] mx-auto bg-white flex flex-col gap-[32px] justify-center mt-5">
        <div className="sm:h-[106px] sm:w-[528px] h-[104px] w-[358px] m-auto text-center flex flex-col sm:gap-[24px] gap-[12px]">
          <span className="font-jakarta sm:text-[50px] sm:leading-[40px] text-[38px] leading-[44px] text-center font-bold">
            Our Blogs
          </span>
          <span className="font-jakarta sm:text-[18px] sm:leading-[14px] text-[16px] leading-[24px] text-[#5F6168]">
            This is where we share our experience and knowledge with you.
          </span>
        </div>
        <div className="sm:h-auto sm:w-full sm:grid sm:grid-cols-3 sm:gap-[26px] h-auto w-[341px] m-auto flex flex-col gap-[20px]">
          {blogs.slice(0, 3).map((item, index) => (
            <div
              key={index}
              className="w-full sm:w-[380px] p-3 hover:shadow-lg hover:scale-105 hover:-translate-y-2 transition-all duration-300 ease-in-out hover:drop-shadow-xl cursor-pointer rounded-[12px]"
              // onClick={() => handleBlogClick(item.id)}
            >
              <div className="h-[220px] w-full rounded-[12px]">
                <img
                  src={item.imageUrl}
                  alt=""
                  className="h-full w-full rounded-[12px]"
                />
              </div>
              <div className="h-auto flex flex-col gap-[12px]">
                <div className="h-auto w-full bg-transparent flex items-center gap-2 mt-3">
                  <div className="h-[24px] w-auto border border-solid border-[#5B6CFF1A] rounded-[30px] uppercase font-jakarta font-bold text-[12px] leading-[8px] flex items-center justify-center text-[#495CD9] p-3">
                    <span>{item.author}</span>
                  </div>
                  <div className="h-[4px] w-[4px] rounded-[4px] bg-[#5F6168]"></div>
                  <span className="font-jakarta font-medium text-[14px] leading-[12px] text-[#787B83]">
                    {formatDate(item.createdAt)}
                  </span>
                </div>
                <span className="font-outfit font-bold text-[24px] leading-[32px] text-[#202124]">
                  {item.title}
                </span>
              </div>
            </div>
          ))}
        </div>
        {showAllBlogs && (
          <div className="sm:h-auto sm:w-full sm:grid sm:grid-cols-3 sm:gap-[26px] h-auto w-[341px] m-auto flex flex-col gap-[20px] mt-5">
            {blogs.slice(3).map((item, index) => (
              <div
                key={index}
                className="sm:h-auto sm:w-[380px] auto w-full bg-white sm:flex sm:flex-col gap-[16px] p-3 hover:shadow-lg hover:scale-105 hover:-translate-y-2 transition-all duration-300 ease-in-out hover:drop-shadow-xl cursor-pointer rounded-[12px]"
                // onClick={() => handleBlogClick(item.id)}
              >
                <div className="h-[220px] w-full rounded-[12px]">
                  <img
                    src={item.imageUrl}
                    alt=""
                    className="h-full w-full rounded-[12px]"
                  />
                </div>
                <div className="h-auto flex flex-col gap-[12px]">
                  <div className="h-auto w-full bg-white flex items-center gap-2 mt-3">
                    <div className="h-[24px] w-auto border border-solid border-[#5B6CFF1A] rounded-[30px] uppercase font-jakarta font-bold text-[12px] leading-[8px] flex items-center justify-center text-[#495CD9] p-3">
                      <span>{item.author}</span>
                    </div>
                    <div className="h-[4px] w-[4px] rounded-[4px] bg-[#5F6168]"></div>
                    <span className="font-jakarta font-medium text-[14px] leading-[12px] text-[#787B83]">
                      {formatDate(item.createdAt)}
                    </span>
                  </div>
                  <span className="font-outfit font-bold text-[24px] leading-[32px] text-[#202124]">
                    {item.title}
                  </span>
                </div>
              </div>
            ))}
          </div>
        )}
        <div
          onClick={handleViewAllBlogs}
          className="h-[52px] w-[185.89px] px-[16px] flex items-center gap-[15px] font-archivo font-semibold text-[17px] leading-[14px] text-[#495CD9] m-auto cursor-pointer"
        >
          <span>{showAllBlogs ? "Show less blogs" : "View all blogs"}</span>
          <img
            src={DownIcon}
            alt=""
            className={`h-[8.17px] w-[8.33px] transition-transform duration-300 ${
              showAllBlogs ? "rotate-180" : ""
            }`}
          />
        </div>
      </div>
    </div>
  );
}

export default Blogs;
