import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./App.css";
import React, { useState, useEffect } from "react";
import Loader from "./components/Loader";
import BlogDescription from "./pages/BlogDescription";
import SPA from "./pages/SPA";

function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  }, []);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <Router>
          <Routes>
            <Route path="/" element={<SPA />} />
            <Route path="/blog/:id" element={<BlogDescription />} />
          </Routes>
        </Router>
      )}
    </>
  );
}

export default App;
