import AOS from "aos";
import "aos/dist/aos.css";
import React, { useEffect, useState } from "react";
import { HashLink as Link } from "react-router-hash-link";
import CustomizationLogo from "../assets/icons/customization-icon.svg";
import IntegrationLogo from "../assets/icons/integration-icon.svg";
import LatesttechLogo from "../assets/icons/latesttech-logo.svg";
import MigrationLogo from "../assets/icons/migration-icon.svg";
import SecurityLogo from "../assets/icons/security-icon.svg";
import Content from "../assets/images/card-content.png";
import ServiceCard from "../components/ServiceCard";

function Features() {
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 680);

  const cardsData = [
    {
      icon: CustomizationLogo,
      title: "Customization",
      description:
        "Unlike off-the-shelf solutions, our customizable platform allows you to modify design, features, and functionality to align with your specific goals. You can create a shopping experience that reflects your brand, integrate advanced features, and adapt the platform as your business grows.",
    },
    {
      icon: IntegrationLogo,
      title: "Integrations",
      description:
        "Our custom eCommerce platform offers a user-friendly API for seamless integration with your existing ERP, CRM, and other systems. It enables smooth synchronization of products and data, ensuring real-time updates and efficient management across all your software systems.",
    },
    {
      icon: MigrationLogo,
      title: "Migration",
      description:
        "We've successfully migrated data from numerous eCommerce platforms to our custom solution. Our experienced team ensures a smooth, efficient migration process, minimizing downtime and disruptions to your business operations, guaranteeing seamless transitions.",
    },
    {
      icon: SecurityLogo,
      title: "Security",
      description:
        "Our platform meets the latest security standards to protect sensitive information and block unauthorised access. We have implemented advanced encryption protocols and secure authentication methods to protect your data. ",
    },
    {
      icon: LatesttechLogo,
      title: "Latest Technology",
      description:
        "Our platform leverages the most advanced technology to deliver high performance and reliable integrating tools and techniques to ensure your eCommerce solution is both modern and efficient, keeping you ahead in the competitive landscape"
    },
  ];

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth >= 680);
    };

    window.addEventListener('resize', handleResize);
    
    if (isLargeScreen) {
      AOS.init({ duration: 2000 });
    }

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isLargeScreen]);

  return (
    <div
      id="features"
      className="w-screen h-auto sm:w-[1192px] m-auto sm:mt-[100px] py-[100px] flex flex-col gap-6"
    >
      <div className="h-auto w-[358px] text-center sm:w-[498px] bg-white mx-auto flex flex-col gap-4">
        <span className="font-outfit font-bold text-[38px] leading-[44px] sm:text-[50px] sm:leading-[60px] text-[#01103D]">
          Our Exciting Features
        </span>
        {/* <span className="font-jakarta text-[16px] sm:text-[20px] leading-[24px] text-[#5F6168] text-center">
        More than 5,000 companies trust and choose Procart
        </span> */}
      </div>
      <div className="flex flex-col gap-[24px] items-center sm:grid sm:grid-cols-3 sm:gap-8 sm:mt-12">
        {cardsData.map((card, index) => (
          <ServiceCard
            key={index}
            icon={card.icon}
            title={card.title}
            description={card.description}
            data-aos={isLargeScreen ? "fade-left" : ""}
          />
        ))}
        <div
          className="sm:w-[375px] w-[358px] bg-white rounded-[30px] gap-[30px] flex flex-col"
          data-aos={isLargeScreen ? "fade-left" : ""}
          data-aos-duration="2000"
        >
          <div className="bg-card-background bg-cover bg-center h-[490px] w-full rounded-t-[30px] rounded-b-[30px] flex flex-col justify-between gap-[21px] pt-[50px] pb-0 pl-[32px] object-contain">
            <div className="flex flex-col gap-[25px]">
            <span className="font-outfit font-semibold text-[28px] leading-[36px] text-white">
              Wondering if these are a good fit for your e-commerce project?
            </span>
            <button className="w-[148.1px] h-[44px] rounded-[30px] bg-[#01103D] text-white font-archivo text-[17px] leading-[14px] font-semibold">
              <Link smooth to="#about">
                Learn More
              </Link>
            </button>
            </div>
            <div className="flex justify-end p-0 m-0">
              <img src={Content} alt="" className="h-[160px]" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Features;
