import React from "react";
import StackedContact from "../assets/images/stacked-contact.png"
import { HashLink as Link } from "react-router-hash-link";
function Contact() {
  return (
    <div className="w-screen sm:h-[800px] h-[944px] sm:grid sm:grid-cols-2 flex flex-col">
      <div className="h-[465px] sm:h-full bg-contact-background sm:bg-cover flex justify-center items-center">
        <div className="sm:h-[349px] sm:w-[544px] h-[352px] w-[326px] flex flex-col gap-[21px] sm:mx-auto m-auto">
          <span className="uppercase font-jakarta text-[14px] leading-[20px] text-white">
            contact for demo
          </span>
          <div className="h-[155px] text-white">
            <span className="font-outfit sm:text-[60px] sm:leading-[80px] text-[48px] leading-[54px] font-extralight">
              Want to get your{" "}
              <span className="font-outfit sm:text-[75px] sm:leading-[75px] text-[48px] leading-[54px] font-semibold">
                business online?
              </span>
            </span>
          </div>
          <div className="h-[56px] sm:w-[382px] text-white">
            <span className="font-jakarta sm:text-[20px] text-[18px] leading-[28px]">
              Share your thought and one of our expert  will get back to you?
            </span>
          </div>
          <button className="h-[54px] w-[180.53px] rounded-[30px] bg-white font-archivo font-semibold text-[17px] leading-[14px] text-center">
            <Link smooth to="#footer">
              Contact Us
            </Link>
          </button>
        </div>
      </div>

      <div className="h-[479px] sm:h-full bg-gradient-to-r from-[#CECDFF] to-[#CDD8FF] flex justify-center items-center">
        <div className="sm:h-[544.44px] sm:w-[531.55px] h-[350px] w-[350px]">
          <img src={StackedContact} alt="" />
        </div>
      </div>
    </div>
  );
}

export default Contact;
