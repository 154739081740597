import React from "react";
import Whatsapp from "../components/Whatsapp";
import Home from "./Home";
import AboutUs from "./AboutUs";
import Demo from "./Demo";
import OurClient from "./OurClient";
import Contact from "./Contact";
import OurServices from "./OurServices";
import Question from "./Question";
import Blogs from "./Blogs";
import Footer from "../components/Footer";

function SPA() {
  return (
    <div>
      <Whatsapp />
      <Home />
      <AboutUs />
      <Demo />
      <OurClient />
      <Contact />
      {/* <OurServices /> */}
      <Question />
      {/* <Blogs /> */}
      <Footer />
    </div>
  );
}

export default SPA;
