import { React, useState } from "react";
import fourthActive from "../assets/icons/changing-icon/ic_outline-sell.svg";
import fifthActive from "../assets/icons/changing-icon/mingcute_git-merge-line.svg";
import pwaActive from "../assets/icons/changing-icon/pwa.svg";
import firstActive from "../assets/icons/changing-icon/solar_cart-linear.svg";
import thirdActive from "../assets/icons/changing-icon/Vector (1).svg";
import secondActive from "../assets/icons/changing-icon/Vector.svg";
import fourthIcon from "../assets/icons/ic_outline-sell.svg";
import fifthIcon from "../assets/icons/mingcute_git-merge-line.svg";
import PWA from "../assets/icons/pwg.svg";
import cart from "../assets/icons/solar_cart-linear.svg";
import thickIcon from "../assets/icons/tick.svg";
import thirdIcon from "../assets/icons/Vector (1).svg";
import secondIcon from "../assets/icons/Vector.svg";
import analysis from "../assets/images/analysis.png";
import backgroundLayers from "../assets/images/bgLayer.jpg";
import messageIcon from "../assets/images/image_01.png";
import socialMeia from "../assets/images/socialMedia.png";
import Features from "./Features";

const accordionData = [
  {
    icon: cart,
    activeIcon: firstActive,
    title: "Microservices-based eCommerce web and app development",
    content:
      "We specialize in microservices-based eCommerce development, offering a cohesive omnichannel experience across web and mobile platforms. Our approach ensures seamless user journeys that drive engagement and conversions. ",
  },
  {
    icon: secondIcon,
    activeIcon: secondActive,
    title: "Backend, frontend, & CRM development",
    content:
      "We build eCommerce stores and apps with a solid backend (often cloud-based) and an engaging frontend to enhance the shopping experience worldwide. Our CRM solutions support your marketing and sales growth.",
  },
  {
    icon: thirdIcon,
    activeIcon: thirdActive,
    title: "Payment gateways",
    content:
      "We create custom, PCI-DSS-compliant payment gateways for your marketplace app or website, ensuring data security and a smooth checkout process. We are experienced in integrating popular payment methods like Stripe, PayPal, eBay, Visa and others to provide a seamless transaction experience.",
  },
  {
    icon: fourthIcon,
    activeIcon: fourthActive,
    title: "Marketplaces",
    content:
      "We create scalable marketplace architectures that connect multiple buyers and sellers on one platform, ensuring smooth operation across the web and mobile.",
  },
  {
    icon: PWA,
    activeIcon: pwaActive,
    title: "PWA eCommerce",
    content:
      "We create Progressive Web Apps (PWAs) that bring a high-quality shopping experience to your browser, eliminating the need for app downloads and installations.",
  },
  {
    icon: fifthIcon,
    activeIcon: fifthActive,
    title: "Aggregator portals",
    content:
      "We design and build custom aggregator portals that curate vendor listings for specific products or services, enhancing consumer access and creating new revenue streams for you.",
  },
];

const AboutUs = () => {
  const [activeAccordion, setActiveAccordion] = useState(null);

  const changeAccordion = (index) => {
    setActiveAccordion(activeAccordion === index ? null : index);
  };

  const [currentIndex, setCurrentIndex] = useState(0);
  const currentDetail = accordionData[currentIndex];

  const whyProcart = [
    { text: "Scale with your business." },
    { text: "Secure and trust" },
    { text: "Customer Friendly" },
    { text: "Fast and SEO Friendly" },
    { text: "Easy 3rd party Integration" },
  ];

  const whyUs = [
    { text: "Undestand your Need" },
    { text: "Develop a plan" },
    { text: "Research throughly" },
    { text: "Meet your custom need" },
    { text: "Design Carefully" },
    { text: "Launch and get live in a month." },
  ];

  return (
    <div>
      <div
        id="about"
        className="relative sm:top-[7px] lg:top-[8px] w-screen z-0"
      >
        {/* Components overlay */}
        <div className="relative sm:w-[1170px] z-10 m-auto w-screen h-auto sm:pt-[50px] py-[35px]">
          <div className="h-auto sm:w-full flex flex-col w-[358px] m-auto">
            <div className="sm:h-[184px] h-[292px] w-full bg-white py-[20px] flex flex-col gap-5">
              <span className="font-outfit font-bold text-[50px] leading-[40px]">
                Why US ?
              </span>
              <p className="font-jakarta text-xl font-medium text-[#5F6168]">
                With over 5 years of eCommerce expertise, we combine our
                development skills with the latest technology to deliver
                exceptional results. Don’t just hire a developer—partner with
                Procart to co-create your eCommerce masterpiece.{" "}
              </p>
            </div>
          </div>
          <div className="flex w-full gap-10">
            <div className="sm:h-auto sm:w-[580px] w-[358px] mx-auto h-auto">
              <div className="h-auto w-full accordion">
                {accordionData.map((item, index) => (
                  <div key={index} className={"mb-2 border-b border-[#EAECF0]"}>
                    <button
                      className={`sm:w-[580px] sm:h-[112px] w-[358px] h-auto font-outfit font-medium sm:p-[32px] text-left sm:flex sm:gap-[20px] items-center grid grid-rows-2 gap-3 ${
                        activeAccordion === index
                          ? "border-none text-2xl pt-[32px] px-[32px] pb-[6px]"
                          : "border-b border-[#EAECF0] text-xl p-[32px]"
                      }`}
                      onClick={() => {
                        changeAccordion(index);
                        setCurrentIndex(index);
                      }}
                    >
                      <div
                        className={
                          "h-[48px] w-[48px] rounded-[28px] flex justify-center items-center bg-[#F2F4F7]"
                        }
                      >
                        <div
                          className={`h-[32px] w-[32px] rounded-[100px] flex items-center justify-center ${
                            activeAccordion === index
                              ? "bg-[#E2DFF6]"
                              : "bg-[#EAECF0]"
                          } `}
                        >
                          <img
                            src={
                              activeAccordion === index
                                ? item.activeIcon
                                : item.icon
                            }
                            alt=""
                            className="h-[18px] w-[18px]"
                          />
                        </div>
                      </div>
                      <span className="sm:w-[417px] text-[#101828]">
                        {item.title}
                      </span>
                    </button>
                    {activeAccordion === index && (
                      <div
                        className={`pl-[32px] sm:pl-[98px] pb-6 sm:p-0 font-jakarta font-medium text-[16px] leading-[24px] text-[#5F6168] rounded-md ${
                          activeAccordion === index
                            ? "border-b-[6px] border-[#8973fe]"
                            : ""
                        }`}
                      >
                        <div className="sm:w-[90%] w-[92%] sm:hidden">
                          <span>{item.content}</span>
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
            <div className="w-full h-[700px] bg-gradient-to-r from-[#F3F8FF] to-[#CEDBFF] hidden rounded-[24px] sm:flex">
              {/* <img src={WhyusBg} alt="" className="h-full w-full" /> */}
              <div className="w-[60%] m-auto h-auto p-auto flex flex-col gap-4 p-5">
                <div
                  className={`h-[60px] w-[60px] rounded-[100px] flex items-center justify-center bg-[#E2DFF6]`}
                >
                  <img
                    src={currentDetail.activeIcon}
                    alt=""
                    className="h-[32px] w-[32px]"
                  />
                </div>
                <div className="font-outfit font-medium text-2xl">
                  <span className="text-[#101828]">{currentDetail.title}</span>
                </div>
                <div className="font-jakarta font-medium text-[16px] leading-[24px] text-[#5F6168]">
                  <span>{currentDetail.content}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="sm:w-full h-auto m-auto sm:p-0 flex flex-col items-center sm:justify-between w-[390px] sm:my-12 justify-center py-[20px]">
            <div className="w-full sm:h-[440px] h-auto flex sm:flex-row flex-col justify-between gap-5 items-center">
              {/* card1 */}
              <div className="sm:w-[570px] sm:h-[440px] bg-gradient-to-r from-[#FFF7F1] to-[#FAE9EF] relative rounded-[40px] overflow-clip shadow-sm w-[358px] h-[465px] z-10">
                <div className="sm:h-[224px] sm:w-[224px] sm:ml-[60px] sm:mt-[66px] flex flex-col justify-between sm:gap-7 w-[358px] h-[196px] gap-2 m-auto ml-7 mt-2">
                  <div className="h-auto w-auto text-[#01103D] font-bold text-[32px]">
                    <p className="font-outfit">Why Procart?</p>
                  </div>
                  <div className="h-[168px] w-full flex flex-col gap-2">
                    {whyProcart.map((item, index) => (
                      <span key={index} className="flex gap-2">
                        <img src={thickIcon} alt="thick_icon" />
                        <p className="font-jakarta">{item.text}</p>
                      </span>
                    ))}
                  </div>
                </div>
                <div className="absolute right-0 bottom-2 z-0 ">
                  <img src={analysis} alt="analysis_Images" />
                </div>
              </div>
              {/* card2 */}
              <div className="sm:w-[570px] sm:h-[440px] bg-gradient-to-r from-[#F3F8FF] to-[#CEDBFF] relative overflow-clip rounded-[40px] shadow-sm w-[358px] h-[465px] z-10">
                <div className="sm:w-[313px] sm:h-[296px] sm:ml-[60px] sm:mt-[66px] flex flex-col sm:gap-7 w-[358px] h-[582.74px] gap-2 m-auto ml-7 mt-2">
                  <div className="h-auto w-auto text-[#01103D] font-bold text-[32px]">
                    <p className="font-outfit">Strategy</p>
                  </div>
                  <div className="h-[168px] w-full flex flex-col gap-2">
                    {whyUs.map((item, index) => (
                      <span key={index} className="flex gap-2">
                        <img src={thickIcon} alt="thick_icon" />
                        <p className="font-jakarta">{item.text}</p>
                      </span>
                    ))}
                  </div>
                </div>
                <div className="absolute right-0 bottom-2 z-0">
                  <div className="relative">
                    <img
                      src={socialMeia}
                      alt="social_media"
                      className="w-full relative"
                    />
                    <div className="absolute right-7 bottom-0 z-10 md:right-5 md:bottom-0 lg:right-7 lg:bottom-0">
                      <img
                        src={messageIcon}
                        width={190}
                        height={190}
                        alt="message_icon"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Background layer */}
        <div className="absolute sm:bottom-[1320px] left-0 right-0 w-screen h-auto z-0 bg-contain bg-no-repeat md:bg-contain lg:bg-cover hidden sm:block 2xl:bottom-[1250px]">
          <img
            src={backgroundLayers}
            alt="background_layers"
            className="w-full h-auto object-cover bg-cover bg-center sm:bg-cover md:bg-contain lg:bg-cover bg-no-repeat"
          />
        </div>
        <div className="relative left-0 z-0">
          <Features />
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
