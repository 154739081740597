import React, { useState } from "react";
import whatsapp from "../assets/icons/whatsapp.svg";

export default function Whatsapp() {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      className="fixed z-10 bottom-[20px] right-[20px] cursor-pointer"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div
        className={`h-[50px] ${
          isHovered ? "w-[220px]" : "w-[50px]"
        } bg-[#2ACE67] rounded-[12px] flex justify-center items-center transition-all duration-700 ease-in-out`}
      >
        <a
          href="https://wa.me/+61414307399"
          target="_blank"
          rel="noopener noreferrer"
          className="flex items-center"
        >
          <img src={whatsapp} alt="Whatsapp" className="h-[30px] w-[30px]" />
          {isHovered && (
            <span className="text-white font-outfit text-[18px] font-bold ml-[10px] whitespace-nowrap">
              Chat on Whatsapp
            </span>
          )}
        </a>
      </div>
    </div>
  );
}
