import React from "react";

function Video({ video }) {
  return (
    <div className="hidden sm:block">
      <video src={video} autoPlay muted className="w-auto"></video>
    </div>
  );
}

export default Video;
