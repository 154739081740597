import React, { useState } from "react";
import Minus from "../assets/icons/minus-icon.svg";
import Plus from "../assets/icons/plus-icon.svg";
import QuestionImage from "../assets/images/question.png";

const accordionData = [
  {
    title: "How long does it take to launch a product with Procart?",
    content:
      "The timeline for launching a product depends on various factors, including the complexity of the project and specific client requirements. On average, you can expect a timeframe of several weeks to a few months.",
  },
  {
    title: "Are Procart's solutions customizable?",
    content:
      "Yes, our solutions are highly customizable to meet the unique needs of your business. We work with you to tailor the platform to your specific requirements, including design, features, and functionality.",
  },
  {
    title: "Do you provide ongoing support and maintenance?",
    content:
      "Yes, we offer both paid and free ongoing support and maintenance options to ensure the smooth functioning of your e-commerce platform. Our team is here to assist with any issues that may arise.",
  },
  {
    title: "Can you migrate my existing e-commerce platform to Procart?",
    content:
      "Absolutely! We provide migration services to help you transition smoothly from your existing platform to Procart, ensuring minimal disruption to your business.",
  },
  {
    title: "Do you offer post-launch support?",
    content:
      "Yes, we provide comprehensive post-launch support to address any issues or concerns and to ensure your e-commerce platform operates efficiently.",
  },
  {
    title: "What payment methods does Procart support?",
    content:
      "We support a wide range of payment methods, including Stripe, credit/debit cards, digital wallets like PayPal and Apple Pay, bank transfers, and more. We can also integrate with specific payment gateways as per your business needs.",
  },
  {
    title: "Can I customize the design of my e-commerce app with Procart?",
    content:
      "Yes, Procart allows you to customize the design of your eCommerce app to reflect your branding and enhance user experience. You can personalize various elements such as branding, user interface, fonts, and graphics.",
  },
  {
    title: "How is Procart’s e-commerce platform different from others?",
    content:
      "Procart offers a full-service eCommerce platform tailored to B2B, B2C, and SaaS projects. We provide end-to-end solutions that help brands evolve from concept to revenue-generating digital products, supported by our 24/7 maintenance and tech support.",
  },
  {
    title:
      "What is the cost of developing an eCommerce website and mobile app?",
    content:
      "The cost varies based on design, functionality, and complexity. Factors such as time and resources allocated also influence the overall cost and duration of the project.",
  },
];

function Question() {
  const [activeAccordion, setActiveAccordion] = useState(null);

  const changeAccordion = (index) => {
    setActiveAccordion(activeAccordion === index ? null : index);
  };

  return (
    <div
      id="faq"
      className="sm:h-[900px] w-screen h-auto bg-white flex items-center m-auto justify-center"
    >
      <div className="sm:h-[737px] w-full">
        {/* <div className="sm:w-[1192px] h-auto w-auto sm:h-full sm:mx-auto grid grid-rows-2 sm:flex sm:gap-[32px] sm:mt-10 mt-0 gap-0"> */}
        <div className="sm:w-[1192px] h-auto w-auto sm:h-full sm:mx-auto flex sm:flex-row flex-col items-start sm:gap-[32px] sm:mt-10 my-8 gap-3">
          <div className="sm:h-full sm:w-[496px] flex flex-col m-auto sm:gap-[20px] gap-[24px] sm:pt-[23px] sm:pl-[39px] pt-0 pl-0">
            <div className="sm:w-[474px] sm:h-[219px] flex flex-col h-[156px] w-[358px] gap-[16px] sm:gap-5">
              <span className="font-outfit font-semibold sm:text-[50px] sm:leading-[60px] text-[38px] leading-[44px] text-[#333333]">
                Have a question?
              </span>
              <span className="font-jakarta sm:text-[18px] sm:leading-[30px] text-[16px] leading-[24px] text-[#6F7782] font-medium">
                Leverage our vast experience in eCommerce app development. We
                offer a range of solutions, including apps, PWAs, and web
                portals, delivering exceptional eCommerce experiences to our
                clients and their users.
              </span>
            </div>
            <div className="sm:h-[408.93px] sm:w-[449.86px] h-[308px] w-[337px]">
              <img src={QuestionImage} alt="" />
            </div>
          </div>
          <div className="sm:h-auto sm:w-[696px] w-[358px] mx-auto sm:mt-10 mt-0">
            <div className="h-auto w-full accordion">
              {accordionData.map((item, index) => (
                <div
                  key={index}
                  className={`sm:mb-4 mb-3 sm:rounded-[16px] rounded-[12px] ${
                    activeAccordion === index
                      ? "bg-gradient-to-r from-[#926EFB] via-[#465BD7] to-[#4A56AB]"
                      : "bg-[#F0F0F5]"
                  }`}
                >
                  <button
                    className={`w-full font-archivo font-semibold sm:px-[30px] text-left px-3 py-3 text-[18px] leading-[24px] rounded-[16px] flex justify-between items-center ${
                      activeAccordion === index ? "text-white" : ""
                    }`}
                    onClick={() => changeAccordion(index)}
                  >
                    <span className="w-[90%]">{item.title}</span>
                    <div
                      className={`h-[30px] w-[30px] rounded-[100px] flex justify-center items-center ${
                        activeAccordion === index
                          ? "bg-white"
                          : "bg-gradient-to-r from-[#926EFB] via-[#465BD7] to-[#4A56AB]"
                      }`}
                    >
                      <img
                        src={activeAccordion === index ? Minus : Plus}
                        alt=""
                      />
                    </div>
                  </button>
                  {activeAccordion === index && (
                    <div
                      className={`sm:px-[30px] sm:py-3 px-3 py-2 font-jakarta font-medium text-[16px] leading-[24px] text-white rounded-b-[16px] ${
                        activeAccordion === index
                          ? "bg-gradient-to-r from-[#926EFB] via-[#465BD7] to-[#4A56AB] whitespace-pre-wrap"
                          : "bg-white"
                      }`}
                    >
                      {item.content}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Question;
