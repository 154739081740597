import React from "react";
import Close from "../assets/icons/cross.svg";
import Menu from "../assets/icons/menu.svg";
import Logo from "../assets/images/procart-logo.png";
import MobilenNavComponents from "./navigation-components/MobilenNavComponents";

function MobileNav({ isNavOpen, handleMenuToggle }) {
  return (
    <div className="w-[358px] h-[32px] relative top-[32px] m-auto z-10">
      <div className="w-full flex justify-between cursor-pointer">
        <img src={Logo} alt="Procart Logo" />
        <img
          src={isNavOpen ? Close : Menu}
          alt="Menu Icon"
          onClick={handleMenuToggle}
        />
      </div>
      {isNavOpen && (
        <MobilenNavComponents
          closeNav={handleMenuToggle}
          navItems={[
            { label: "Home", link: "/#home" },
            { label: "About", link: "/#about" },
            { label: "Features", link: "/#features" },
            { label: "FAQ", link: "/#faq" },
            // { label: "Blogs", link: "#blogs" },
          ]}
        />
      )}
    </div>
  );
}

export default MobileNav;
